<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-4 p-1">
        <label>Modelo de Documento:</label>
        <span class="p-fluid">
          <Dropdown
            v-model="modeloDocumento"
            :options="modelosDocumento"
            optionLabel="nome_modelo_documento"
            placeholder="Selecione um Modelo"
            @change="findTipoFiltro"
          />
        </span>
      </div>
    </div>

    <div class="row justify-content-center">
      <div
        class="col-12 col-md-2 p-1"
        :class="hasContribuinteTipos ? 'col-md-2' : 'col-md-4'"
      >
        <label for="tipoTomador">Filtro:</label>
        <span class="p-fluid">
          <Dropdown
            v-model="selectedFiltro"
            :options="filtrosConfiguracao"
            optionLabel="tipoFiltroDescricao"
            placeholder="Selecione um Filtro"
            @change="findContribuinteTipo"
          />
        </span>
      </div>

      <div class="col-12 col-md-2 p-1" v-if="hasContribuinteTipos">
        <label for="tipoTomador"
          >{{
            selectedFiltro && selectedFiltro.tipoFiltro
              ? `Tipo de ${formatText(selectedFiltro.tipoFiltro)}:`
              : "Tipo:"
          }}
        </label>
        <span class="p-fluid">
          <Dropdown
            v-model="contribuinteTipo"
            :options="contribuinteTipos"
            optionLabel="descricaoContribuinteTipo"
            placeholder="Selecione um Tipo"
          />
        </span>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-12 col-md-4 mb-3 p-1">
        <label for="tipoTomador">{{ labelInputDocumento }}</label>
        <span
          class="p-fluid"
          v-if="
            tipoFiltroSelected === 'CNPJ' ||
            tipoFiltroSelected === 'CNPJ_INSCRIÇÃO'
          "
        >
          <InputMask
            @blur="validarDocumento()"
            v-model="filtro"
            key="CNPJ"
            mask="99.999.999/9999-99"
            placeholder="Informe o CNPJ"
          />
        </span>

        <span class="p-fluid" v-else-if="tipoFiltroSelected === 'INSCRIÇÃO'">
          <InputText
            v-model="filtro"
            key="INSCRICAO"
            placeholder="Informe a Inscrição Municipal"
            maxlength="20"
          />
        </span>

        <span
          class="p-fluid"
          v-else-if="
            tipoFiltroSelected === 'CPF' ||
            tipoFiltroSelected === 'CPF_INSCRIÇÃO'
          "
        >
          <InputMask
            @blur="validarDocumento()"
            v-model="filtro"
            key="CPF"
            mask="999.999.999-99"
            placeholder="Informe o CPF"
          />
        </span>
      </div>
    </div>

    <div class="row justify-content-center align-items-end">
      <div class="col-md-4 mb-3 d-flex justify-content-center">
        <span class="p-fluid">
          <vue-recaptcha
            ref="recaptcha"
            :sitekey="$store.getters.getKeyRecaptcha"
            @verify="handleSuccess"
            @expired="handleError"
            @render="handleError"
            @error="handleError"
          />
        </span>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-12 col-md-4 mb-3 p-1 d-flex justify-content-end">
        <Button
          label="Imprimir"
          class="p-button-primary"
          @click="imprimirDocumento"
          icon="pi pi-print"
          :disabled="captchaState"
        />
      </div>
    </div>
  </div>
  <Pessoa ref="modalCadastroPessoa" />
  <Dialog
    header="Atenção"
    :visible="displayConfirmation"
    :containerStyle="{ width: '350px' }"
    :modal="true"
  >
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
      <span>Pessoa não encontrada, deseja cadastrar agora?</span>
    </div>
    <template #footer>
      <Button
        label="Não"
        icon="pi pi-times"
        @click="hideConfirmCadastrarPessoa()"
        class="p-button-text"
      />
      <Button
        label="Sim"
        icon="pi pi-check"
        @click="cadastrarPessoa()"
        class="p-button-text"
        autofocus
      />
    </template>
  </Dialog>
</template>

<script>
import Util from "@/utils/util";
import DocumentoService from "@/services/documento.service";
import PortalService from "@/services/portal.service";
import Pessoa from "@/views/usuario/FormCadastroUsuario.vue";
import { VueRecaptcha } from "vue-recaptcha";

export default {
  name: "Documento",

  components: {
    VueRecaptcha,
    Pessoa,
  },

  data() {
    return {
      loading: false,
      captchaState: true,

      modeloDocumento: null,
      modelosDocumento: [],
      selectedFiltro: null,
      filtrosConfiguracao: [],
      filtro: "",

      contribuinteTipo: "",
      contribuinteTipos: [],

      dadosDocumento: "",
      displayConfirmation: false,
    };
  },

  async created() {
    await this.$root.fetchFuncionalidades();
    await this.fetchModelosDocumento();
  },

  computed: {
    funcionalidades() {
      return JSON.parse(JSON.stringify([...this.$root.menuFuncionalidades]));
    },
    idFuncionalidade() {
      const funcionalidadesFiltered = this.funcionalidades.filter(
        (v) =>
          v.pagina &&
          v.pagina !== null &&
          v.pagina.toLowerCase() === "documento"
      );
      return funcionalidadesFiltered[0] ? funcionalidadesFiltered[0].id : null;
    },
    idCliente() {
      return this.$store.getters.getIdCliente;
    },
    serverExec() {
      return this.$store.getters.getServerExec;
    },
    idUsuario() {
      return this.$store.getters.getIdUsuario;
    },
    hasContribuinteTipos() {
      return this.contribuinteTipos.length > 0;
    },

    tipoFiltroSelected() {
      return this.selectedFiltro && this.selectedFiltro.tipo_filtro;
    },
    labelInputDocumento() {
      if (this.tipoFiltroSelected) {
        const tipoFiltro = this.tipoFiltroSelected;

        if (tipoFiltro === "CPF" || tipoFiltro === "CPF_INSCRIÇÃO")
          return "CPF";
        if (tipoFiltro === "CNPJ" || tipoFiltro === "CNPJ_INSCRIÇÃO")
          return "CNPJ";
        if (tipoFiltro === "INSCRIÇÃO") return "Inscrição Municipal";
      }

      return "";
    },
  },

  methods: {
    cadastrarPessoa() {
      this.hideConfirmCadastrarPessoa();
      this.$refs.modalCadastroPessoa.show();
      this.$refs.modalCadastroPessoa.documento = this.filtro;
      this.$refs.modalCadastroPessoa.tipoFiltro = this.tipoFiltroSelected;
    },
    async fetchModelosDocumento() {
      try {
        this.$store.dispatch("SET_ENABLE_SPINNER", true);
        const dados = {
          idCliente: this.idCliente,
          token: this.$store.getters.getTrbToken
        };
        this.modelosDocumento = await DocumentoService.findAll(this.serverExec, dados);
      } catch (errorMessage) {
        this.alerta(
          "error",
          "Houve um problema ao listar os modelos de documento!"
        );
      } finally {
        this.modeloDocumento = this.modelosDocumento[0];
        this.findTipoFiltro({
          value: this.modeloDocumento,
        });
        this.$store.dispatch("SET_ENABLE_SPINNER", false);
      }
    },

    async findTipoFiltro(event) {
      try {
        //const idModeloDocumento = event.value && event.value.id_modelo_documento;
        this.$store.dispatch("SET_ENABLE_SPINNER", true);
        const result = event.value.filtros;

        this.filtrosConfiguracao = result.map((v) =>
          v.tipo_filtro === "CPF_INSCRIÇÃO"
            ? {
                ...v,
                tipoFiltroDescricao: "CPF / INSCRIÇÃO",
              }
            : {
                ...v,
                tipoFiltroDescricao: v.tipo_filtro,
              }
        );
      } catch (errorMessage) {
        this.alerta("error", "Houve um problema ao listar os tipos de filtro!");
      } finally {
        this.selectedFiltro = this.filtrosConfiguracao[0];

        console.log(this.selectedFiltro);
        this.findContribuinteTipo({
          value: this.selectedFiltro,
        });
        this.$store.dispatch("SET_ENABLE_SPINNER", false);
      }
    },

    async findContribuinteTipo(event) {
      try {
        const tipoFiltro = event.value && event.value.tipo_filtro;
        this.$store.dispatch("SET_ENABLE_SPINNER", true);
        this.contribuinteTipos =
          await PortalService.findFuncionalidadeContribuinteTipo(
            this.idCliente,
            this.idFuncionalidade,
            tipoFiltro
          );
      } catch (errorMessage) {
        this.alerta("error", "Houve um problema ao listar as filtros!");
      } finally {
        this.contribuinteTipo =
          this.contribuinteTipos.length > 0 ? this.contribuinteTipos[0] : "";
        this.$store.dispatch("SET_ENABLE_SPINNER", false);
      }
    },

    handleSuccess() {
      this.captchaState = false;
    },

    handleError() {
      this.captchaState = true;
    },

    alerta(type, msg) {
      this.$toast.add({
        severity: type,
        summary: msg,
        life: 3000,
      });
    },

    hideConfirmCadastrarPessoa() {
      this.displayConfirmation = false;
    },

    validarDocumento() {
      if (!this.filtro) {
        return false;
      }

      var validar = false;
      validar = Util.validarCPFCNPJ(this.filtro);

      if (!validar && this.filtro) {
        this.alerta(
          "error",
          this.tipoFiltroSelected + " " + this.filtro + " inválido!"
        );
        this.filtro = "";
        return false;
      }

      const dados = Promise.resolve(
        DocumentoService.findPessoa(this.idCliente, this.filtro)
      );
      dados.then((retorno) => {
        if (!retorno) {
          if (
            this.tipoFiltroSelected == "CPF" ||
            this.tipoFiltroSelected == "CNPJ"
          ) {
            for (
              let index = 0;
              index < this.filtrosConfiguracao.length;
              index++
            ) {
              if (
                this.tipoFiltroSelected ==
                  this.filtrosConfiguracao[index].tipoFiltro &&
                this.filtrosConfiguracao[index].idBolPermiteCadastrarPessoa ==
                  "S"
              ) {
                this.displayConfirmation = true;
              }
            }
          }
        }
      });

      return validar;
    },

    formatText(text) {
      const textReturn = text === "CPF_INSCRIÇÃO" ? "Inscrição" : text;
      const lowerCase = textReturn.toLowerCase();
      return lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1);
    },

    async imprimirDocumento() {
      const identificador = await this.gerar();
      if (!identificador.false) {
        await this.imprimir(identificador);
      }
    },

    async gerar() {
      try {
        this.$store.dispatch("SET_ENABLE_SPINNER", true);

        await this.$root.getTrbToken;

        const gerar = {
          idCliente: this.idCliente,
          idUsuario: this.idUsuario,
          idAmbiente: this.$store.getters.getIdAmbiente,
          idModeloDocumento: this.modeloDocumento && this.modeloDocumento.id_modelo_documento,
          idContribuinteTipo:
            this.contribuinteTipo !== "" ? this.contribuinteTipo.id : "",
          tipoEmissao:
            this.selectedFiltro.tipo_filtro === "INSCRIÇÃO"
              ? "INSCRICAO"
              : this.selectedFiltro.tipo_filtro,
          documento: this.filtro,
          token: this.$store.getters.getTrbToken,
        };

        this.dadosDocumento = DocumentoService.gerar(this.serverExec, gerar);

        //Recupera mensagem de erro retornada pela API
        const retorno = Promise.resolve(this.dadosDocumento);
        retorno.then((erro) => {
          if (erro.false[0]) {
            this.alerta("error", erro.false[0]);
            return false;
          }
        });

        return this.dadosDocumento;
      } catch (errorMessage) {
        this.alerta("error", "Houve um problema ao gerar documento!");
      } finally {
        this.$store.dispatch("SET_ENABLE_SPINNER", false);
      }
    },

    async imprimir(identificador) {
      try {
        this.$store.dispatch("SET_ENABLE_SPINNER", true);

        await this.$root.getTrbToken;

        const imprimir = {
          idTrbDocsEmitidos: identificador.true
            ? identificador.true.join()
            : "",
          idCliente: this.idCliente,
          idUsuario: this.idUsuario,
          token: this.$store.getters.getTrbToken,
        };

        await DocumentoService.imprimir(this.serverExec, imprimir);
      } catch (errorMessage) {
        this.alerta("error", "Houve um problema ao imprimir documento!");
      } finally {
        this.$store.dispatch("SET_ENABLE_SPINNER", false);
      }
    },
  },
};
</script>
